import React, {useEffect, useState} from "react";
import Layout from "../components/layout";
import env from "../config/env";

const openPaywallNConfig = {
  action: env.openPaywallNAction,
  accessToken: env.GATSBY_STRIPE_PUBLIC_KEY,
  cost: env.vipMemberCost
};

const useStateWithLocalStorageForEmail = localStorageKey => {

  //get email value from localStorage
  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = React.useState(
    typeof window !== "undefined" && localStorage.getItem(localStorageKey) || '',
  );

  //set user email value in localStorage
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, emailValueFromLocalStorage);
  }, [emailValueFromLocalStorage]);

  return [emailValueFromLocalStorage, setEmailValueFromLocalStorage];
};

const useStateWithLocalStorageForLast4 = localStorageKey => {

  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = React.useState(
    typeof window !== "undefined" && localStorage.getItem(localStorageKey) || '',
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, last4ValueFromLocalStorage);
  }, [last4ValueFromLocalStorage]);

  return [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage];
};

const LogoutPage =  ({props, data}) => {

  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = useStateWithLocalStorageForEmail('email');
  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = useStateWithLocalStorageForLast4('last4');

  useEffect(() => {
    setEmailValueFromLocalStorage('');
    setLast4ValueFromLocalStorage('');

    window.location.replace("/");
  }, [emailValueFromLocalStorage, last4ValueFromLocalStorage]);

  return (
    <Layout className="site-content"></Layout>
  )
};

export default LogoutPage
